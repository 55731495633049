import React from 'react';
import './Footer.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebookF, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

const Footer = () => {
  return (
    <div className="footer-section">
      <div className="footer-container">
        {/* Logo and text on the left */}
        <div className="footer-left">
          <img src="/footerLogo.png" alt="Logo" className="footer-logo" />
          <p className="footer-description">
            Thank you for your interest in the Libyan Engineering Consulting Office. We are committed to delivering excellence in architectural and engineering solutions.
          </p>
        </div>

        {/* Quick Links on the right */}
        <div className="footer-right">
          <div className="footer-columns">
            {/* Column 1 */}
            <div className="footer-column">
              <h4>Quick Links</h4>
              <ul className="footer-links">
                <li><a href="#about">About Us</a></li>
                <li><a href="#projects">Projects</a></li>
                <li><a href="#services">Services</a></li>
                <li><a href="#news">News & Insights</a></li>
                <li><a href="#careers">Careers</a></li>
                <li><a href="#contact">Contact Us</a></li>
              </ul>
            </div>
            {/* Column 2 */}
            <div className="footer-column">
            <h4>Resources</h4>
              <ul className="footer-links">
               
                <li><a href="#faq">FAQ</a></li>
                <li><a href="#blog">Blog</a></li>
                <li><a href="#case-studies">Case Studies</a></li>
                <li><a href="#testimonials">Client Testimonials</a></li>
              </ul>
            </div>
            {/* Column 3 */}
            <div className="footer-column">
            <h4>Legal</h4>
              <ul className="footer-links">
              
                <li><a href="#privacy">Privacy Policy</a></li>
                <li><a href="#terms">Terms of Service</a></li>
                <li><a href="#cookie">Cookie Policy</a></li>
              </ul>
              <div className="footer-social-icons">
                <a href="https://www.instagram.com/leo_engineering_consultancy?igsh=MzRlODBiNWFlZA=="><FontAwesomeIcon icon={faInstagram} /></a>
                <a href="https://www.facebook.com/share/AV77PGDkxVe3Zp3R/?mibextid=LQQJ4d"><FontAwesomeIcon icon={faFacebookF} /></a>
                <a href="https://linkedin.com"><FontAwesomeIcon icon={faLinkedinIn} /></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
