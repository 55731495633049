import React from 'react';
import './MobileFooter.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebookF, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

const MobileFooter = () => {
  return (
    <div className="mobile-footer-section">
      <div className="mobile-footer-content">
        <p className="mobile-footer-description">
          Thank you for your interest in the Libyan Engineering Consulting Office. We are committed to delivering excellence in architectural and engineering solutions.
        </p>

        <div className="mobile-footer-social-icons">
          <a href="https://www.instagram.com/leo_engineering_consultancy?igsh=MzRlODBiNWFlZA==">
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a href="https://www.facebook.com/share/AV77PGDkxVe3Zp3R/?mibextid=LQQJ4d">
            <FontAwesomeIcon icon={faFacebookF} />
          </a>
          <a href="https://linkedin.com">
            <FontAwesomeIcon icon={faLinkedinIn} />
          </a>
        </div>

        <p className="mobile-footer-copyright">
          © 2024 Libyan Engineering Office (LEO). All rights reserved.
        </p>
      </div>
    </div>
  );
};

export default MobileFooter;
