import React, { useState } from 'react';
import './Section6Mobile.css';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faFacebookF, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';

const Section6Mobile = () => {
  const [formData, setFormData] = useState({
    subscriber_name: '', // Matches backend field for name
    to_email: '', // Matches backend field for recipient email
    message: '' // Matches backend field for message
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const sendEmail = (e) => {
    e.preventDefault();
    console.log('Sending form data:', formData); // Debug log for form data

    axios.post('https://leobackend-1.onrender.com/send-email', formData)
      .then((response) => {
        alert(response.data.message);
        setFormData({ subscriber_name: '', to_email: '', message: '' });
      })
      .catch((error) => {
        alert('Failed to send email. Please try again.');
        console.error('Error:', error);
      });
  };

  return (
    <div className="section6-mobile" id="section6-mobile">
      <div className="section6-mobile-left-content">
        <h1>Looking for More Ways to Connect?</h1>
        <p>
          Visit our Contact Page for detailed information on how to reach our various offices and departments. <br />
          Whether you’re looking for specific department contacts, office locations, and more, for general inquiries you can submit your message from here. <br />
          Your information will be kept confidential and used solely for the purpose of responding to your inquiries.
        </p>
      </div>

      <div className="section6-mobile-right-content">
        <div className="section6-mobile-text-box">
          <input 
            type="text" 
            name="subscriber_name" // Name matches backend field
            placeholder="Your Name" 
            value={formData.subscriber_name} 
            onChange={handleChange} 
          />
        </div>
        <div className="section6-mobile-text-box">
          <input 
            type="text" 
            name="to_email" // Name matches backend field
            placeholder="Your Email" 
            value={formData.to_email} 
            onChange={handleChange} 
          />
        </div>
        <div className="section6-mobile-text-box large-box">
          <input 
            type="text" 
            name="message" // Name matches backend field
            placeholder="Your Message" 
            value={formData.message} 
            onChange={handleChange} 
          />
        </div>

        <button className="section6-mobile-submit-button" onClick={sendEmail}>Submit</button>
      </div>
    </div>
  );
};

export default Section6Mobile;
