import React, { useState, useEffect } from 'react';
import SplashScreen from './SplashScreen';
import Section2 from './Section2';
import Section3 from './Section3';
import Gallery from './Gallery';
import Section4 from './Section4';
import Section5 from './Section5';
import Section6 from './Section6';
import ColorSchemesExample from './ColorSchemesExample';
import './Styles.css';
import { Link, Element } from 'react-scroll';
import Footer from './Footer';
import MergedSection from './MergedSection';
import Section3Mobile from './Section3Mobile';
import Section4Mobile from './Section4Mobile';
import Section5Mobile from './Section5Mobile';
import Section6Mobile from './Section6Mobile';
import MobileFooter from './MobileFooter';

function App() {
  const [showSplash, setShowSplash] = useState(true);
  const [showContent, setShowContent] = useState(false);

  useEffect(() => {
    // Simulate loading time
    setTimeout(() => {
      setShowSplash(false); // Hide the splash screen after some time
      setShowContent(true); // Show the website content
    }, 3000); // Adjust the duration as needed
  }, []);

  return (
    <div className="App">
      {showSplash ? (
        <SplashScreen />
      ) : (
        <div className={`website-content ${showContent ? 'show-content' : ''}`}>
          <ColorSchemesExample />
          <Element name="Gallery">
            <Gallery />
          </Element>
          
     <Section2/>
     <Section3/>
     <Section3Mobile/>
          <Section4 />
          <Section4Mobile/>
          <Section5 />
          <Section5Mobile/>
          <Section6 />
          <Section6Mobile/>
          <Footer />
          <MobileFooter/>
        </div>
      )}
    </div>
  );
}

export default App;
