// ColorSchemesExample.js

import React from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import 'bootstrap/dist/css/bootstrap.min.css';
import './ColorSchemesExample.css'; // Import CSS file for styling
import './Fonts/fonts.css'; 

function ColorSchemesExample() {
  return (
    <div className="navbar-overlay">
      <Navbar bg="dark" variant="dark" className="navbar-transparent">
        <Container>
          {/* Logo and brand */}
          <div className="logo-container">
            <Navbar.Brand className="d-flex align-items-center">
              <img
                src={process.env.PUBLIC_URL + '/logo.png'}
                width="10"
                height="10"
                className="d-inline-block align-top mr-3"
                alt="Logo"
              />
              <span className="h5 mb-0 ml-2">The Libyan Engineering Consulting Co.</span>
            </Navbar.Brand>
          </div>
          {/* Navigation links */}
          <Nav className='linkat'>
            <Nav.Link href="#home">Home</Nav.Link>
            <Nav.Link href="#about">About</Nav.Link>
            <Nav.Link href="#projects">Projects</Nav.Link>
            <Nav.Link href="#contact">Contact</Nav.Link>
            <Nav.Link href="#contact">Ar</Nav.Link>
          </Nav>
        </Container>
      </Navbar>
    </div>
  );
}

export default ColorSchemesExample;
