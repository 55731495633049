import React from 'react';
import './Section5.css';

const Section5 = () => (
  <div className="section5" id="section5">
    <div className="section-title">Core Expertise</div>
    <div className="square-container">
      <div className="expertise-square">Consulting Services</div>
      <div className="expertise-square">Architecture</div>
      <div className="expertise-square">Urban Planning</div>
    </div>
    <div className="footer-content">
      <div className="footer-text"></div>
      <button className="footer-button">Click Here</button>
    </div>
  </div>
);

export default Section5;
