import React, { useState } from 'react';
import './Section5Mobile.css';

const Section5Mobile = () => {
  const [expandedIndex, setExpandedIndex] = useState(null);

  const toggleExpand = (index) => {
    setExpandedIndex(expandedIndex === index ? null : index);
  };

  const expertiseItems = [
    { title: 'Consulting Services', description: 'We provide expert consulting services tailored to your needs.' },
    { title: 'Architecture', description: 'Our architectural designs focus on innovation and sustainability.' },
    { title: 'Urban Planning', description: 'We specialize in urban planning that enhances community living.' },
  ];

  return (
    <div className="section5-mobile" id="section5-mobile">
      <div className="section-title">We're Experts</div>
      <div className="expertise-container">
        {expertiseItems.map((item, index) => (
          <div
            key={index}
            className={`expertise-card ${expandedIndex === index ? 'expanded' : ''}`}
            onClick={() => toggleExpand(index)}
          >
            <div className={`card-title ${expandedIndex === index ? 'active' : ''}`}>
              {item.title}
            </div>
            <div className="card-arrow">&#9660;</div> {/* Downward arrow indicator */}
            {expandedIndex === index && (
              <div className="card-description">{item.description}</div>
            )}
          </div>
        ))}
      </div>
      <div className="explore-content">
        <div className="explore-text"></div>
        <button className="explore-button">Learn More</button>
      </div>
    </div>
  );
};

export default Section5Mobile;
