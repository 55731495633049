import React, { useState, useEffect } from 'react';
import './Section4.css';

const galleryImages = [
  {
    src: '/Section401.jpg',
    place: 'Tripoli Seafront Complex Project - 2021',
    description: 'Our primary objective was to maximize the breathtaking views of the Mediterranean while ensuring ample natural ventilation across all residential units.',
  },
  {
    src: '/Section402.jpg',
    place: 'Benghazi Modern Hospital - Architecture Design 2020',
    description: 'Benghazi Hospital is a cornerstone of healthcare in Benghazi, designed with a focus on functionality and patient accessibility. This facility is recognized for its strategic design.',
  },
  {
    src: '/Section403.jpg',
    place: 'Al-ahly Mall - Architecture Design 2021',
    description: 'Nestled in the vibrant city of Benghazi, the Al Ahly Mall and Hotel project stands as a beacon of luxury and tranquility.',
  },
  {
    src: '/Section404.jpg',
    place: 'Ouzo Hotel Development project - architecture design 2021',
    description: 'The Ouzo Hotel, situated in the picturesque location of Benghazi, Libya, overlooking the 23rd of July Lake. Known for its historical significance and unique location, in the city and its residents.',
  },
];

const Section4 = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % galleryImages.length);
    }, 10000); // Automatically change images every 10 seconds

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="section4" id="section4">
      <div className="section4-content-wrapper">
        {/* Left Hand Side */}
        <div className="section4-left-content">
          <h2 className="section4-section-title">Projects Gallery</h2>
          <p className="section4-description">
            Explore some of our impactful projects across <br />a variety of sectors 
            that reflect our dedication in every  <br /> blueprint.
          </p>
          <img src="/Sec4Dots.png" alt="Dots" className="section4-dots-image" />
        </div>

        {/* Right Hand Side */}
        <div className="section4-right-content">
          <div className="section4-gallery-container">
            <img
              src={galleryImages[currentIndex].src}
              alt={galleryImages[currentIndex].place}
              className="section4-gallery-image"
            />
            {/* Progress Indicators */}
            <div className="section4-progress-indicators">
              {galleryImages.map((_, index) => (
                <div
                  key={index}
                  className={`section4-indicator ${index === currentIndex ? 'active' : ''}`}
                  onClick={() => setCurrentIndex(index)} // Allow manual clicking of indicators
                ></div>
              ))}
            </div>
            {/* Place Title and Description */}
            <div className="section4-place-name">{galleryImages[currentIndex].place}</div>
            <div className="section4-description-text">
              {galleryImages[currentIndex].description}
            </div>
          </div>
          <button className="section4-learn-more-button">Learn More</button>
        </div>
      </div>
    </div>
  );
};

export default Section4;
