import React, { useState, useEffect } from 'react';
import { TailSpin } from 'react-loader-spinner';
import './SplashScreen.css';
import { faLinkedin, faInstagram, faFacebook } from '@fortawesome/free-brands-svg-icons';

import './Fonts/fonts.css'; 

const SplashScreen = () => {
    const [imagesLoaded, setImagesLoaded] = useState(false);
    const [hideSplash, setHideSplash] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setImagesLoaded(true);
        }, 5000);
    }, []);

    const handleAnimationEnd = () => {
        setHideSplash(true);
    };

    return (
        <div className={`splash-screen ${imagesLoaded ? 'hide' : ''}`} onAnimationEnd={handleAnimationEnd}>
            <img src={process.env.PUBLIC_URL + '/Leo_logo_sc_mobile.png'} alt="Image 1" className="animated-element" />
            {!imagesLoaded && (
                <div className="loading-container-animated-element">
                    <TailSpin
                        height="45"
                        width="45"
                        color="#808080"
                        ariaLabel="loading"
                        margin-top="10%"
                    />
                </div>
            )}
              <p class="Arabic_Text"> الشركة الليبية للاستشارات الهندسية <br/>
            <br/>
            
            </p>
            <p class="English_Text"> 
            The Libyan Engineering Consulting Co. <br/>
            
            </p>
            <img src={process.env.PUBLIC_URL + '/group23.png'} alt="Image 3" className="animated-element" />
          
            <img src={process.env.PUBLIC_URL + '/allright.png'} alt="Image 4" className="animated-element" />
        </div>
    );
};

export default SplashScreen;
