import React, { useState, useEffect, useRef } from 'react';
import './Section3Mobile.css';

const galleryImages = [
  { src: '/CorrectSection401.jpg', title: 'A Glimpse into the Past', body: 'Exploring our heritage, a selection of project photos.' },
  { src: '/CorrectSection4002.jpg', title: 'New generations of Libyan architects', body: 'Shaping the Future of Design in Libya.' },
  { src: '/CorrectSection4003.jpg', title: 'Engineers Syndicate Conference', body: 'Benghazi Construction Exhibition' },
  { src: '/CorrectSection4004.jpg', title: 'Shahat Declared a Sustainable Region', body: 'Pioneering Sustainable Urban Development in Libya\'s Green Heart' },
];

const Section3Mobile = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const slidesRef = useRef([]);

  // Use IntersectionObserver to track the centered slide
  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5, // 50% of the slide should be visible to consider it "centered"
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const index = parseInt(entry.target.getAttribute('data-index'), 10);
          setCurrentIndex(index);
        }
      });
    }, observerOptions);

    slidesRef.current.forEach((slide) => {
      if (slide) observer.observe(slide);
    });

    return () => {
      slidesRef.current.forEach((slide) => {
        if (slide) observer.unobserve(slide);
      });
    };
  }, []);

  const handleIndicatorClick = (index) => {
    setCurrentIndex(index);
    document.getElementById(`mobile-slide-${index}`).scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
  };

  return (
    <div className="section3-mobile" id="section3-mobile">
      <div className="section3-mobile-title">News & Insights</div> {/* Title at the top */}
      <div className="section3-scroll-container-mobile">
        {galleryImages.map((image, index) => (
          <div
            key={index}
            className={`section3-slide-mobile ${index === currentIndex ? 'active' : ''}`}
            id={`mobile-slide-${index}`}
            data-index={index}
            ref={(el) => (slidesRef.current[index] = el)}
          >
            <div className="section3-slide-banner-mobile">
              <h2 className="section3-slide-title-mobile">{image.title}</h2>
              <p className="section3-slide-body-mobile">{image.body}</p>
            </div>
            <img src={image.src} alt={image.title} className="section3-slide-image-mobile" />
          </div>
        ))}
      </div>
      <div className="section3-progress-indicators-mobile">
        {galleryImages.map((_, index) => (
          <div
            key={index}
            className={`section3-indicator-mobile ${index === currentIndex ? 'active' : ''}`}
            onClick={() => handleIndicatorClick(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Section3Mobile;
