import React, { useState, useEffect } from 'react';
import './Section2.css';

const images = [
  { 
    src: '/Section201.jpg', 
    title: 'Building Legacies', 
    body: 'With over six decades of excellence, we have been a cornerstone in shaping modern Libya, combining innovation with a deep respect for tradition.', 
    project: '01- The 7000 Housing units project - 1985' 
  },
  { 
    src: '/Section202.jpg', 
    title: 'A Vision for Tomorrow', 
    body: 'Guided by a forward-thinking vision, we continue to redefine the engineering landscape, pioneering projects that stand the test of time.', 
    project: '02- Government Administration Complex - 1960\'s' 
  },
  { 
    src: '/Section203.jpg', 
    title: 'Architecting the Future', 
    body: 'Our architectural expertise merges creativity with functionality, designing iconic structures that define Libya’s skyline and enrich its cultural heritage.', 
    project: '03- E.Fathi Jaouda - 1959' 
  },
];

const Section2 = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 10000); // Change image every 10 seconds

    return () => clearInterval(interval); // Cleanup on component unmount
  }, []);

  const handleIndicatorClick = (index) => {
    setCurrentIndex(index); // Set current index on indicator click
  };

  const currentImage = images[currentIndex];

  return (
    <div className="section2" id="section2">
      <div className="section2-content-container">
        <div className="section2-text-content">
          <h2>{currentImage.title}</h2>
          <p>{currentImage.body}</p>
          <button className="section2-learn-more">Learn More</button>
        </div>
        <div className="section2-gallery-content">
          <img src={currentImage.src} alt={currentImage.title} className="section2-gallery-image" />
          <div className="section2-small-title">
            {currentImage.project}
          </div>
        </div>
        <img src="/sec23.png" alt="Decorative Line" className="section2-line" />
      </div>

      <div className="section2-progress-indicators">
        {images.map((_, index) => (
          <div
            key={index}
            className={`section2-indicator ${index === currentIndex ? 'active' : ''}`}
            onClick={() => handleIndicatorClick(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Section2;
